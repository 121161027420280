.search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: url("../../assets/bg_search_bar.png") 0 0 no-repeat;
    width: 954px;
    height: 64px;
}

.flex-wrapper {
    flex: 4 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.autocomplete-input {
    width: 250px;
    padding: 5px;
    margin: -3px 0 0 0px;
    padding: 0 0 0 21px;
    width: 669px;
    float: left;
    height: 36px;
}

.autocomplete-input-box {
    width: 100%;
    padding: 5px 1px;
    margin: 0;
    padding: 0;
    height: 36px;
    line-height: 36px;
    width: 672px;
    font-family: Arial;
    font-size: 14px;
    color: #7d7d80;
    border: 0;
    background: transparent;
    outline: none;
}

.suggestions-list {
    position: relative;
    list-style: none;
    padding: 0;
    max-height: 160px;
    overflow-y: auto;
    z-index: 1;
    width: 670px;
    border: 1px solid #aaaaaa;
    background: #fff url('../../assets/ui-bg_flat_75_ffffff_40x100.png') 50% 50% repeat-x;
    color: #313146;
    margin-top: 0px;
}

.suggestion {
    padding: 5px;
    cursor: pointer;
    margin: 0;
    text-align: left;
    color: #076bb2;
}

.suggestion:hover {
    background-color: #ccc;
    color: #4d4d4d;
    font-weight: bold;
}

.search-btn {
    background: url('../../assets/b_search_data-6d941ecd9d4b5b6fd3cf05af09c4bbba.png') 0 0 no-repeat;
    position: relative;
    display: block;
    text-decoration: none;
    text-indent: -9999px;
    border: none;
    width: 132px;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    margin-left: 20px;
    flex: 1 1 auto;
}

.search-reset {
    flex: 1 1 auto;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
    cursor: pointer;
    text-decoration: none;
    text-indent: -9999px;
    /* background: url('../../assets/b_reset-4cf771f94608fac40e5880aea23b2228.png') 0 0 no-repeat; */
    width: 34px;
    height: 34px;
    line-height: 34px;
    margin-left: 30px;
}

.selected {
    background-color: #ccc;
    color: #4d4d4d;
    font-weight: bold;
}