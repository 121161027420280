.headings {
  padding-top: 45px;
  display: flex;
}

.user-select {
  width: 300px;
  padding-top: 10px;
}

label {
  display: flex;
  padding-top: 20px;
}

.buttonToLink {
  background: none;
  border: none;
  color: #0000FF
}

.buttonToLink:hover {
  background: none;
  text-decoration: underline;
}

.grid-section {
  margin-top: 20px;
  padding-top: 21px;
  margin-bottom: 60px;
  box-shadow: rgb(0 0 0 / 10%) 0px 2px 10px 7px
}

.width-cell {
  width: 500px;
}

.search-input {
  width: 30%;
  padding: 12px 20px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-right: 20px;
  display: flex;
  float: right;
}