.step-3-wrapper {
  padding-bottom: 20px;
  text-align: left;
}

.step-3-wrapper .list-builder-header {
  border-bottom: 1px solid #e1e1e1;
  padding: 10px 0px 15px 0px;
  font-size: 26px;
  line-height: 29px;
  color: #224f6d;
  text-align: left;
  width: 96%;
}

.step-3-wrapper .state-filter-wrapper {
  margin: 15px 0px;
}

.step-3-wrapper .state-filter-wrapper .sic-label, .step-3-wrapper .naics-code-wrapper .naics-label  {
  font-size: 23px;
  line-height: 26px;
  color: #224f6d;
}

.step-3-wrapper .state-filter-wrapper .toggle-label, 
.step-3-wrapper .naics-code-wrapper .toggle-label, 
.step-3-wrapper .size-filter-wrapper .toggle-label, 
.step-3-wrapper .distribution-filter-wrapper .toggle-label  {
  color: #076bb2;
  margin-left: 20px;
  font-size: 14px;
  text-decoration: none;
  border-bottom: 1px dotted;
  cursor: pointer;
}



.step-3-wrapper .state-filter-wrapper span.toggle-label, 
.step-3-wrapper .naics-code-wrapper span.toggle-label, 
.step-3-wrapper .size-filter-wrapper a.toggle-label:hover, 
.step-3-wrapper .distribution-filter-wrapper a.toggle-label:hover  {
  text-decoration: underline;
  color: #076bb2;
  text-decoration: underline;
  outline: none;
}

.step-3-wrapper .state-filter-wrapper .toggle-label :hover {
  text-decoration: underline;
}

.sic-select-wrapper, .naics-select-wrapper {
  display: flex;
  justify-content: space-between;
  width: 52%;
  align-items: baseline;
  padding: 15px 0;
}

.naics-select-wrapper .checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.naics-select-wrapper .checkbox-container label {
  margin-top: 10px;
}

.sic-select, .naics-select {
  width: 300px;
}

.data_help {
  display: block;
  margin: 0 0 0 40px;
  padding: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-decoration: none;
  text-indent: -9999px;
  background: url('../../../../assets/ico_question.png') 0 0 no-repeat;
  cursor: pointer;
}

.data_help:hover {
  background-position: 0 -18px;
}

.state-filter-wrapper .data_help,
.naics-code-wrapper .data_help {
  display: inline-block;
  vertical-align: bottom;
}
