.app-footer {
  font-size: 13px;
  display: flex;
  bottom: 0px;
  background-color: #d0dfe4;
  height: 60px;
  width: 100vw;
}

.app-footer a {
  color: #313146;
  text-decoration: none;
  line-height: 17px;
}

.app-footer a:hover {
  color: #4891c5;
}

.footer-inner-container {
  margin: 0 auto;
  width: 70%;
  display: flex;  
  justify-content: space-between;
  align-items: center;
  font-family: sans-serif;
}

.align-left {
  text-align: left;
}

.footer-inner-container .link-wrapper a {
  display: block;
  float: left;
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.footer-inner-container span {
  width: 1.5px;
  background-color: #becbcf;
  display: inline-block;
  height: 12px;
}

.footer-inner-container .link-wrapper .lnk-facebook {
  margin-right: 10px;
  background: url('https://qa.aggdata.com/assets/ico_facebook-0b36ad1705f2b4221df5b2de2fb158a2.png') 0 0 no-repeat;
}
.footer-inner-container .link-wrapper .lnk-twitter {
  margin-right: 10px;
  background: url('https://qa.aggdata.com/assets/ico_twitter-85fb0e54cceddd41ce5e6ac34340fbb0.png') 0 0 no-repeat;
}
.footer-inner-container .link-wrapper .lnk-linkedin {
  background: url('https://qa.aggdata.com/assets/ico_linkedin-36d65c50024e59e0e1f1efd60e3fc3f6.png') 0 0 no-repeat;
}