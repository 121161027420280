html {
  background: #fbfbfb url('./assets/bg_site.png') 0 0 repeat-x;
  height: 100%
}

body {
  padding: 0;
  margin: 0px;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

h1, h2 {
  font-family: "dederon-sans-web",sans-serif;
}

h2 {
  padding-bottom: 10px;
  font-size: 23px;
  line-height: 26px;
  color: #224f6d;
}

div#site_wrapper div#site_container {
  margin: 0 auto;
  width: 960px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
  background: transparent;
  color: #313146;
  font-size: 14px;
  line-height: 21px;
}

.app {
  text-align: center;
  width: 100%;
  margin: 0 auto;
}
.header-container {
  height: 80px;
  justify-content: left;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.app-container {
  width: 100%;
  margin: 0 auto;
}
.control-wrapper {
  padding: 20px 0px;
  height: 160px;
  background-color: #bfcdd2;
}
.main-content {
  width: 70%;
}

p {
  margin: 0 0 20px 0;
  padding: 0;
}