.lb-metadata-wrapper {
  text-align: left;
}
.elem-set {
  padding: 5px;
}

.elem-set p {
  margin: 0 0 20px 0;
  padding: 0;
}

#single_col {
  float: left;
  padding-top: 40px;
  padding-bottom: 20px;
  width: 100%;
  text-align: left;
}

#single_col h4 {
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 20px;
  color: #224f6d;
  font-weight: bold;
}