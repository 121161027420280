.step-1-wrapper {
  padding-bottom: 20px;
  text-align: left;
}

.step-1-wrapper .list-builder-header {
  border-bottom: 1px solid #e1e1e1;
  padding: 30px 0px 15px 0px;
  font-size: 26px;
  line-height: 29px;
  color: #224f6d;
  text-align: left;
  width: 96%;
}

.step-1-wrapper .sic-code-wrapper {
  margin: 15px 0px;
}

.step-1-wrapper .sic-code-wrapper .sic-label,
.step-1-wrapper .naics-code-wrapper .naics-label {
  font-size: 23px;
  line-height: 26px;
  color: #224f6d;
}

.step-1-wrapper .sic-code-wrapper .toggle-label,
.step-1-wrapper .naics-code-wrapper .toggle-label {
  text-decoration: underline;
  color: #076bb2;
  margin-left: 20px;
  font-size: 14px;
  cursor: pointer;
}

.step-1-wrapper .sic-code-wrapper .toggle-label :hover {
  text-decoration: underline;
}

.sic-select-wrapper,
.naics-select-wrapper {
  display: flex;
  justify-content: space-between;
  width: 52%;
  align-items: baseline;
  padding: 15px 0;
}

.naics-select-wrapper .checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.naics-select-wrapper .checkbox-container label {
  margin-top: 10px;
}

.sic-select,
.naics-select {
  width: 300px;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}


.accordion-title {
  width: 100%;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
  color: blue;
}

.accordion-content ul {
  list-style-type: none;
  padding-left: 0pt;
}

.accordion-content ul {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}

.accordion-content .movable-item {
  display: flex;
  justify-content: flex-start;
  margin-left: 10px;
}

.accordion-title-text {
  margin-left: 10px;
}

.auto-height-col {
  display: block !important;
  max-height: 500px;
  overflow-y: scroll;
}