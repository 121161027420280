.historical-section {
  text-align: left;
}

.historical-tool-wrapper {
  background-color: lightgray;
  padding: 15px;
}

.historical-tool-wrapper .historical-snapshot-label {
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 20px;
  color: #224f6d;
  font-weight: bold;
}

.frequency-wrapper {
  width: 50%;
}

.frequency-wrapper .frequency-label {
  padding-bottom: 10px;
  font-size: 18px;
  line-height: 20px;
  color: #224f6d;
  font-weight: bold;
}

.frequency-wrapper .frequency-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.frequency-wrapper .snapshot-table {
  border: 1px solid lightblue;
  padding: 8px 5px;
}

.frequency-wrapper .snapshot-table .snapshot-table-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tolerance-wrapper {

}

.tolerance-wrapper .edit-tolerance-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}