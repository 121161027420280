.user-pref-wrapper .inner-container {
  margin: 0 auto;
  padding: 20px 0px;
}

.nav-button-wrapper {
  display: flex;
  display: flex;
  justify-content: space-between;
  padding: 10px 0 0 0;
}

.nav-button-wrapper .nav-section {
  width: 202px;
  height: 210px;
}

.nav-button-wrapper .list-builder {
  background: url('https://qa.aggdata.com/assets/b_list_builder-225791fc1c8fd94665529f119f189d77.png') no-repeat;
}

.nav-button-wrapper .list-builder:hover {
  background-position: 0 -210px;
}

.nav-button-wrapper .data-browser {
  background: url('https://qa.aggdata.com/assets/b_data_browser-8082ddae4425216b5a9a52f90a391935.png') no-repeat;
}

.nav-button-wrapper .data-browser:hover {
  background-position: 0 -210px;
}

.nav-button-wrapper .user-pref {
  background: url('https://qa.aggdata.com/assets/b_user_preferences-ab7fdf78a652f473d98dc9efb82aef17.png') no-repeat;
}

.nav-button-wrapper .user-pref:hover {
  background-position: 0 -210px;
}

.nav-button-wrapper .user-control-btns {
  width: 294px;
  height: 210px;
  padding-top: 16px;
  background: url('https://qa.aggdata.com/assets/bg_login_box-452d7f4b7d5a005d3de6f8a5dbdbfe44.png') no-repeat;
}

.nav-button-wrapper .user-control-btns .greet-user {
  display: block;
  font-size: 21px;
  font-weight: bold;
  color: #4d4d4d;
  padding: 0 0 14px 14px;
  text-align: left;
}

.nav-button-wrapper .user-control-btns .btn-wrapper {
  margin: 11px 0 10px 15px;
  padding: 0;
}

.nav-button-wrapper .user-control-btns .btn-wrapper .user-btns {
  margin: 0 2px 4px 0;
  padding: 0;
  background: url('https://qa.aggdata.com/assets/b_white-d472f562e0e96a14e229b6e859283e4c.png') 0 0 no-repeat;
  width: 131px;
  height: 38px;
  float: left;
  display: block;
  line-height: 38px;
  color: #076bb2;
  text-decoration: none;
  line-height: 38px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.nav-button-wrapper .user-control-btns .btn-wrapper a.user-btns {
  display: block;
  text-align: center;
  line-height: 38px;
}

.nav-button-wrapper .user-control-btns .btn-wrapper .user-btns:hover {
  background-position: 0 -38px;
}

.nav-button-wrapper .user-control-btns .btn-wrapper .logout {
  background: url('https://qa.aggdata.com/assets/b_logout-b0fa1481e1b3a3a1f90cd9e565c1c33f.png') 0 0 no-repeat;
  margin: 0;
  width: 133px;
  display: block;
  float: right;
  height: 40px;
  line-height: 40px;
  text-decoration: none;
  text-indent: -9999px;
  text-align: left;
  cursor: pointer;
}

.nav-button-wrapper .user-control-btns .btn-wrapper .logout:hover {
  background-position: 0 -40px;
}

.list-builder-section {
  margin: 0 auto;
}

.list-builder-section .list-builder-header {
  border-bottom: 1px solid #e1e1e1;
  padding: 30px 0px 15px 0px;
  font-size: 26px;
  line-height: 29px;
  color: #224f6d;
  text-align: left;
  width: 96%;
}
