.step-2-wrapper {
  text-align: left;
}

.step-2-wrapper .list-builder-header {
  border-bottom: 1px solid #e1e1e1;
  padding: 30px 0px 15px 0px;
  font-size: 16px;
  line-height: 29px;
  color: #224f6d;
  text-align: left;
  width: 96%;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  margin-top: 10px;
}

.column {
  height: max-content;
  min-height: 100px;
  width: 50%;
  margin: 10px;
  height: 690px;
  max-height: 690px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 10px;
}

div[data-rbd-droppable-context-id] {
  height: max-content;
  min-height: 100px;
  width: 50% !important;
  margin: 10px;
  height: 690px;
  max-height: 690px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 10px;
}

.do-it-column {
  background-color: #e1e1e1;
}

.in-progress-column {
  background-color: #e1e1e1;
}

div[data-rbd-draggable-context-id] {  
  padding: 1px 5px;
  min-width: 160px;
  margin: 1px auto;
  display: flex;
  font-size: 12px;
  align-items: center;
  text-align: flex-start;
}

.movable-item {
  padding: 1px 5px;
  min-width: 160px;
  margin: 1px auto;
  display: flex;
  font-size: 12px;
  align-items: center;
  text-align: flex-start;
}

.movable-item:hover {
  border: 1px dotted red;
}

